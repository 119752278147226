export enum UserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface Role {
  id: number;
  name: string;
}

export interface Status {
  active: boolean;
  message: string;
}

export type User = {
  id?: string;
  name: string;
  role: Role;
  email: string;
  status?: Status;
  deleted?: boolean;
  createAt?: string;
  userMartins: string;
};

export type UserDetail = {
  id: string;
};
