'use client';

import { useState } from 'react';
import { useDebounce } from 'react-use';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { addDays, formatDate } from 'date-fns';
import { isEqual } from 'lodash';

import { WeekSelect } from '~/shared';

import {
  StyledCampaignFormRow,
  StyledCampaignFormSubtitle,
} from '../../components/elements';
import { useGetCampaign, useUpdateCampaign } from '../../hooks';

function fixDateFormat(dt: Date) {
  return `${formatDate(dt, 'yyyy-MM-dd')}T${formatDate(dt, 'HH:mm:ss')}-03:00`;
}

export function RowTime({
  campaign,
  updateCampaignQuery,
}: {
  campaign: ReturnType<typeof useGetCampaign>['data'];
  updateCampaignQuery: ReturnType<typeof useUpdateCampaign>;
}) {
  const [startDate, setStartDate] = useState<Date | null | undefined>(
    campaign?.startDate
  );
  const [finalDate, setFinalDate] = useState<Date | null | undefined>(
    campaign?.endDate
  );

  const [weekdays, setWeekdays] = useState<string[]>(
    campaign?.dayOfWeek || ['0', '1', '2', '3', '4', '5', '6']
  );

  useDebounce(
    () => {
      if (!campaign) return;

      if (!isEqual(weekdays, campaign?.dayOfWeek ?? [])) {
        updateCampaignQuery.mutate({
          id: campaign.id,
          dayOfWeek: weekdays.map(day => Number(day)),
        });
      }

      if (
        (startDate &&
          campaign.startDate &&
          formatDate(startDate, 'dd/MM/yyyy HH:mm:ss') !==
            formatDate(campaign.startDate, 'dd/MM/yyyy HH:mm:ss')) ||
        (startDate && !campaign.startDate)
      ) {
        updateCampaignQuery.mutate({
          id: campaign.id,
          startDate: fixDateFormat(startDate),
        });
      }

      if (
        (finalDate &&
          campaign.endDate &&
          formatDate(finalDate, 'dd/MM/yyyy HH:mm:ss') !==
            formatDate(campaign.endDate, 'dd/MM/yyyy HH:mm:ss')) ||
        (finalDate && !campaign.endDate)
      ) {
        updateCampaignQuery.mutate({
          id: campaign.id,
          endDate: fixDateFormat(finalDate),
        });
      }
    },
    1000,
    [weekdays, startDate, finalDate]
  );

  return (
    <>
      <StyledCampaignFormSubtitle>Tempo de atuação</StyledCampaignFormSubtitle>
      <StyledCampaignFormRow>
        <DatePicker
          sx={{ flexGrow: 1, flexBasis: '100%' }}
          label="Data Inicial"
          value={startDate}
          onChange={value => setStartDate(value)}
          views={['year', 'month', 'day']}
          slotProps={{
            textField: {
              variant: 'filled',
            },
          }}
        />

        <TimePicker
          disabled={!startDate}
          sx={{ flexGrow: 1, flexBasis: '100%' }}
          label="Horário Inicial"
          value={startDate}
          onChange={value => setStartDate(value)}
          orientation="landscape"
          slotProps={{
            textField: {
              variant: 'filled',
            },
          }}
        />

        <DatePicker
          sx={{ flexGrow: 1, flexBasis: '100%' }}
          label="Data Final"
          value={finalDate}
          minDate={startDate ? addDays(startDate, 1) : null}
          onChange={value => setFinalDate(value)}
          views={['year', 'month', 'day']}
          slotProps={{
            textField: {
              variant: 'filled',
            },
          }}
        />

        <TimePicker
          disabled={!finalDate}
          sx={{ flexGrow: 1, flexBasis: '100%' }}
          label="Horário Final"
          value={finalDate}
          onChange={value => setFinalDate(value)}
          slotProps={{
            textField: {
              variant: 'filled',
            },
          }}
        />

        <WeekSelect selectedWeekdays={weekdays} setter={setWeekdays} />
      </StyledCampaignFormRow>
    </>
  );
}
