import React from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import IconButton from '@mui/material/IconButton';

interface SortIconProps {
  sortOrder: 'asc' | 'desc' | false;
  onClick: () => void;
  sx?: object;
}

const SortIcon: React.FC<SortIconProps> = ({ sortOrder, onClick, sx }) => {
  const getIconStyles = () => ({
    marginBottom: '3px',
    color: sortOrder ? 'primary.main' : 'inherit',
    ...sx,
  });

  return (
    <IconButton onClick={onClick}>
      {sortOrder === 'desc' ? (
        <ArrowDownwardIcon fontSize="small" sx={getIconStyles()} />
      ) : (
        <ArrowUpwardIcon fontSize="small" sx={getIconStyles()} />
      )}
    </IconButton>
  );
};

export default SortIcon;
