import { useQuery, useQueryClient } from '@tanstack/react-query';

import { serviceApi } from '~/features';
import { clearParams, clearParamsSort, parseDate } from '~/shared';
import type { Campaign, CampaignListDTO, CampaignListModelView } from '~/types';

export const GET_CAMPAIGNS_QUERY_KEY = 'GET_CAMPAIGNS_QUERY';

async function getCampaigns(params: CampaignListDTO) {
  let clearedParams;
  if (
    !params.orderCampaign ||
    !params.orderDevice ||
    !params.orderDevice ||
    !params.orderInitial ||
    !params.orderFinish
  ) {
    clearedParams = clearParamsSort(params);
  } else {
    clearedParams = clearParams(params);
  }

  const response = serviceApi.get<CampaignListModelView>(`/Campanha/List`, {
    params: clearedParams,
  });

  return response;
}

export function useGetCampaigns(params: CampaignListDTO) {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: [GET_CAMPAIGNS_QUERY_KEY, params],
    select: data => {
      const returnData = data?.data;

      return {
        ...returnData,
        list: returnData?.list.map(item => {
          return {
            ...item,
            startDate: parseDate(item.startDate),
            endDate: parseDate(item.endDate),
            createdAt: parseDate(item.createdAt),
            updatedAt: parseDate(item.updatedAt),
            deletedAt: parseDate(item.deletedAt),
          };
        }),
      };
    },
    queryFn: () => getCampaigns(params),
  });

  return Object.assign(query, {
    refetchAll: () =>
      queryClient.refetchQueries({ queryKey: [GET_CAMPAIGNS_QUERY_KEY] }),
  });
}

export type UseGetCampaignsReturn = ReturnType<typeof useGetCampaigns>['data'];

export type UseGetCampaignsReturnList = Array<
  Omit<
    Campaign,
    'startDate' | 'endDate' | 'createdAt' | 'updatedAt' | 'deletedAt'
  > & {
    startDate: Date | null;
    endDate: Date | null;
    createdAt: Date | null;
    updatedAt: Date | null;
    deletedAt: Date | null;
  }
>;
