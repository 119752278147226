'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useSession } from 'next-auth/react';
import { Add } from '@mui/icons-material';
import { Button, ButtonProps } from '@mui/material';

import roles from '~/types/constants/roles';

type CreateCampaignButtonProps = Omit<
  ButtonProps,
  'variant' | 'color' | 'size' | 'sx' | 'startIcon'
> & {
  forceShow?: boolean;
};

const routesToHideButton = ['/campanhas/nova', '/anunciantes'];

export function CreateCampaignButton({
  forceShow = false,
  ...props
}: CreateCampaignButtonProps) {
  const pathname = usePathname();

  const showNewCampaignButton = !routesToHideButton.includes(pathname);

  const session = useSession();
  const role = session?.data?.user?.role;

  if (role !== roles[0]) {
    return (
      (showNewCampaignButton || forceShow) && (
        <Link href="/campanhas/nova" passHref>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            sx={theme => ({
              textTransform: 'none',
              py: theme.spacing(1),
              px: theme.spacing(2),
              [theme.breakpoints.down('lg')]: {
                py: theme.spacing(3),
                fontSize: theme.typography.pxToRem(18),
                display: forceShow ? 'flex' : 'none',
                width: '90%',
                mx: 'auto',
              },
            })}
            startIcon={<Add />}
            {...props}
          >
            Nova Campanha
          </Button>
        </Link>
      )
    );
  }
}
