import { CampaignListDTO } from '~/types';

export function clearParams(params: Record<string, unknown>) {
  return Object.entries(params).reduce<Record<string, unknown>>(
    (acc, [key, value]) => {
      if (value !== undefined && value !== null && value !== '' && key) {
        acc[key] = value;
      }

      return acc;
    },
    {}
  );
}

export function clearParamsSort(params: CampaignListDTO): CampaignListDTO {
  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([, value]) => value !== false)
  );

  return {
    count: params.count,
    page: params.page,
    ...filteredParams,
  } as CampaignListDTO;
}
