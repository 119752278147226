import { type User } from 'next-auth';
import axios, { type AxiosInstance, isAxiosError } from 'axios';
import { jwtDecode } from 'jwt-decode';

import { capitalize } from '~/shared';
import type { AuthModelView, DecodedMartinsToken } from '~/types';

import { MartinsAuthError, type MartinsAuthResponse } from '../types';

export function getAuthErroFromMessage(message: string | null) {
  const errorCode = message?.split(': ')[1];
  if (!errorCode || !Object.keys(MartinsAuthError).includes(errorCode)) {
    return MartinsAuthError[1];
  }
  return MartinsAuthError[errorCode as keyof typeof MartinsAuthError];
}

export function makeMartinsUser({
  token,
  serviceToken,
  refreshToken,
  name,
  email,
  role,
  registration,
}: {
  token: string;
  serviceToken: string;
  refreshToken: string;
  name: string;
  email: string;
  role?: string;
  registration: string;
}) {
  return {
    name: capitalize(name),
    email,
    token,
    role,
    serviceToken,
    refreshToken,
    registration,
  } as User;
}

export async function authWithUsernameAndPassword(
  axiosInstance: AxiosInstance,
  username: string,
  password: string
) {
  if (
    !process.env.MARTINS_AUTH_API_URL ||
    !process.env.MARTINS_AUTH_APP_CERTIFICATE
  ) {
    throw new Error(MartinsAuthError[16]);
  }

  try {
    const authResponse = await axios.post<MartinsAuthResponse>(
      process.env.MARTINS_AUTH_API_URL,
      {
        Usuario: username,
        Senha: password,
        AdAutenticacao: 'MRT',
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'X-ARR-ClientCert': process.env.MARTINS_AUTH_APP_CERTIFICATE,
        },
      }
    );

    if (!authResponse.data.token) throw new Error(MartinsAuthError[7]);

    const decodedToken = jwtDecode<DecodedMartinsToken>(
      authResponse.data.token
    );

    const serviceAuthorization = await axiosInstance.post<AuthModelView>(
      '/Autenticacao/Auth',
      {
        accessToken: authResponse.data.token,
      }
    );

    if (!serviceAuthorization.data.token) throw new Error(MartinsAuthError[7]);

    const user = makeMartinsUser({
      token: authResponse.data.token,
      serviceToken: serviceAuthorization.data.token,
      refreshToken: serviceAuthorization.data.refreshToken,
      name: serviceAuthorization.data.user.name,
      email: serviceAuthorization.data.user.email,
      role: serviceAuthorization.data.user.role,
      registration: decodedToken.matricula,
    });

    return user;
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.code === 'ECONNREFUSED') {
        throw new Error(MartinsAuthError[17]);
      }
      if (!error.response?.data.mensagem) {
        throw new Error(error.code);
      }
      throw new Error(getAuthErroFromMessage(error.response?.data.mensagem));
    }
    throw new Error(MartinsAuthError[12]);
  }
}

export async function authRefreshToken(
  axiosInstance: AxiosInstance,
  martinsToken: string,
  accessToken: string,
  refreshToken: string,
  registration: string
) {
  if (!refreshToken && !accessToken) throw new Error(MartinsAuthError[13]);

  try {
    const authResponse = await axiosInstance.post<AuthModelView>(
      `/Autenticacao/refresh-token`,
      {
        accessToken,
        refreshToken,
      },
      { headers: { 'Content-Type': 'application/json', Accept: '*/*' } }
    );

    if (!authResponse.data.token) throw new Error(MartinsAuthError[7]);

    const user = makeMartinsUser({
      token: martinsToken,
      serviceToken: authResponse.data.token,
      refreshToken: authResponse.data.refreshToken,
      name: authResponse.data.user.name,
      email: authResponse.data.user.email,
      role: authResponse.data.user.role,
      registration,
    });

    return user;
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.code === 'ECONNREFUSED') {
        throw new Error(MartinsAuthError[17]);
      }
      if (!error.response?.data.mensagem) {
        throw new Error(error.code);
      }
      throw new Error(getAuthErroFromMessage(error.response?.data.mensagem));
    }
    throw new Error(MartinsAuthError[12]);
  }
}
